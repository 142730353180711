import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { StateProvider } from "./services/StateProvider";
import reducer, { initialState } from "./services/reducer";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import { ToastContainer } from "react-toastify";

ReactDOM.render(
  <StateProvider initialState={initialState} reducer={reducer}>
    {/* <Provider store={store}> */}
      <ToastContainer
        position="bottom-right"
        // className="bg-green-300"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <App />
    {/* </Provider> */}
  </StateProvider>,
  document.getElementById("root")
);
